<template>
  <div class="d-flex">
    <admin-message-list-card
      :title="category.title"
      :image="category.image"
      :image_selected="category.image_selected"
      v-for="category in categories"
      :key="category.title"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import AdminMessageListCard from "./AdminMessageListCard.vue";
export default {
  components: { AdminMessageListCard },
  data() {
    return {
      categories: [
        {
          title: "Email",
          image: require("@/assets/svg/message/icon_email.svg"),
          image_selected: require("@/assets/svg/message/icon_email_selected.svg")
        },
        {
          title: "SMS",
          image: require("@/assets/svg/message/icon_sms.svg"),
          image_selected: require("@/assets/svg/message/icon_sms_selected.svg")
        },
        // {
        //   title: "Chat",
        //   image: require("@/assets/svg/message/icon_chat.svg"),
        // },
        {
          title: "History",
          image: require("@/assets/svg/message/icon_history.svg"),
          image_selected: require("@/assets/svg/message/icon_history_selected.svg")
        }
      ]
    };
  },
  computed: {
    ...mapState("auth", {
      profileType: "type"
    })
  },
  methods: {},
  mounted() {
    if (!this.$route.query.category) {
      this.$router.replace({
        query: { category: "Email" }
      });
    }
  }
};
</script>
