<template>
  <div
    @click="goToDetails"
    :class="{ selected, 'hover-item list-item rounded-b-0': true }"
  >
    <div class="mr-3">
      <v-img :src="selected ? image_selected : image" width="20" height="20" />
    </div>
    <div
      class="app-medium-font"
      :class="selected ? 'dark-gray-color' : 'white--text'"
    >
      {{ title }}
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    title: String,
    image: String,
    image_selected: String
  },
  data() {
    return {
      selected: false
    };
  },
  methods: {
    ...mapActions("notification", {
      markAsRead: "markAsRead",
      deleteNotification: "deleteNotification"
    }),
    goToDetails() {
      this.$router.replace({
        query: { category: this.title, q: this.$route.query.q }
      });
    },
    getTimeAgo(oldTimeStr) {
      const oldTime = Date.parse(oldTimeStr);
      const time = new Date();
      const different = (time.getTime() - oldTime) / 1000;
      const month = (different / 3600 / 24 / 30) | 0;
      const days = (different / 3600 / 24) % 30 | 0;
      const hours = (different / 3600) % 24 | 0;
      const mins = (different / 60) % 60 | 0;
      if (month == 1) return `1 month`;
      if (month) return `${month} months`;
      if (days == 1) return `1 day`;
      if (days) return `${days} days`;
      if (hours == 1) return `1 hours`;
      if (hours) return `${hours} hours`;
      if (mins == 1) return `1 min`;
      if (mins) return `${mins} min`;
      return `less than 1 min`;
    }
  },
  watch: {
    "$route.query.category": function(newValue) {
      if ((!newValue && this.title == "All") || newValue == this.title) {
        this.selected = true;
      } else {
        this.selected = false;
      }
    }
  },
  mounted() {
    if (
      (!this.$route.query.category && this.title == "All") ||
      this.$route.query.category == this.title
    ) {
      this.selected = true;
    } else {
      this.selected = false;
    }
  }
};
</script>
<style scoped>
.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.selected {
  background: #eef3f8;
}
.list-item {
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 20px;
  border-radius: 10px;
}
.list-item:hover {
  background: #55eef3f8;
}
</style>
