<template>
  <div class="w-100 min-width-200 mx-5 d-flex flex-column">
    <div class="d-flex flex-column mt-5">
      <span class="white--text font-30 app-medium-font pl-5 mb-3">{{
        $t("send_message")
      }}</span>
      <admin-message-tab-bar-section />
    </div>
    <div></div>
    <div class="w-100 align-center d-flex flex-column pb-5">
      <admin-message-email-section v-if="category == 'Email'" />
      <admin-message-sms-section v-else-if="category == 'SMS'" />
      <!-- <admin-message-chat-section v-else-if="category == 'Chat'" /> -->
      <admin-message-history-section v-else-if="category == 'History'" />
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapState } from "vuex";
import AdminMessageEmailSection from "./AdminMessageEmailSection.vue";
import AdminMessageSmsSection from "./AdminMessageSmsSection.vue";
import AdminMessageTabBarSection from "./AdminMessageTabBarSection";
import AdminMessageHistorySection from "./AdminMessageHistorySection.vue";

export default {
  components: {
    AdminMessageEmailSection,
    AdminMessageSmsSection,
    AdminMessageTabBarSection,
    AdminMessageHistorySection
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      category: ""
    };
  },
  computed: {
    ...mapState("auth", {
      profileType: "type"
    })
  },
  watch: {
    "$route.query.category": function(newValue) {
      this.category = newValue;
    }
  },
  mounted() {
    this.category = this.$route.query.category;
  }
};
</script>
